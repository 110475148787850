import React from 'react';
import { useWeb3React } from '@web3-react/core';
import { Button } from '@mui/material';
import { useEagerConnect } from '../hooks/useEagerConnect';
import { useInactiveListener } from '../hooks/useInactiveListener';
import { connectorsByName } from '../utils/connectors';

const ConnectWalletButton = (props) => {
  const context = useWeb3React();
  const { library, chainId, connector, account, activate, active, deactivate } = context;

  // handle logic to recognize the connector currently being activated
  const [activatingConnector, setActivatingConnector] = React.useState<any>();
  React.useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
  const triedEager = useEagerConnect();

  // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
  useInactiveListener(!triedEager || !!activatingConnector);

  const currentConnector = connectorsByName.injected;
  const connected = currentConnector === connector;

  const switchNetwork = async () => {
    try {
      await library.provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x13881' }],
      });
    } catch (switchError) {
      // 4902 error code indicates the chain is missing on the wallet
      if (switchError.code === 4902) {
        try {
          await library.provider.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: '0x13881',
                rpcUrls: ['https://rpc-mumbai.maticvigil.com/'],
                chainName: 'Mumbai Testnet',
                nativeCurrency: { name: 'MATIC', decimals: 18, symbol: 'MATIC' },
                blockExplorerUrls: ['https://mumbai.polygonscan.com/'],
              },
            ],
          });
        } catch (error) {
          console.error(error);
        }
      }
    }
  };
  if (chainId != 80001 || 137) {
    switchNetwork();
  }

  return active ? (
    <>
      <Button sx={{ margin: 1 }} variant="contained">
        <p color="white">
          {account && `${account.slice(0, 6)}...${account.slice(account.length - 4, account.length)}`}
        </p>
      </Button>
    </>
  ) : (
    <Button
      sx={{ margin: 1 }}
      variant="contained"
      onClick={() => {
        setActivatingConnector(currentConnector);
        activate(currentConnector);
      }}
    >
      Connect Wallet
    </Button>
  );
};

export default ConnectWalletButton;
