import { ReactNode } from 'react';

import DesignServicesTwoToneIcon from '@mui/icons-material/DesignServicesTwoTone';
import BrightnessLowTwoToneIcon from '@mui/icons-material/BrightnessLowTwoTone';

export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

const menuItems: MenuItems[] = [
  {
    heading: 'Dashboard',
    items: [
      {
        name: 'Dashboard',
        link: '/dashboard',
        icon: DesignServicesTwoToneIcon,
      },
    ],
  },
  {
    heading: 'Content',
    items: [
      {
        name: 'Collections',
        link: '/content/collections',
        icon: BrightnessLowTwoToneIcon,
      },
    ],
  },
];

export default menuItems;
